@font-face {
  font-family: 'PP Neue Machina';
  src: url('/fonts/PPNeueMachina/PPNeueMachina-PlainMedium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'PP Neue Machina';
  src: url('/fonts/PPNeueMachina/PPNeueMachina-PlainRegular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

/* latin-ext */
//@font-face {
//  font-family: 'DM Sans';
//  font-style: normal;
//  font-weight: 400;
//  src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Hp2ywxg089UriCZ2IHTWEBlwu8Q.woff2) format('woff2');
//  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
//}
/* latin 400, 500, 700*/
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Hp2ywxg089UriCZOIHTWEBlw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriAWCrCBimC3YU-Ck.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriASitCBimC3YU-Ck.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Ninja Template */
@font-face {
  font-family: 'Font Awesome';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("/fonts/font-awesome/fa-regular-400.eot");
  src: url("/fonts/font-awesome/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("/fonts/font-awesome/fa-regular-400.woff2") format("woff2"), url("/fonts/font-awesome/fa-regular-400.woff") format("woff"), url("/fonts/font-awesome/fa-regular-400.ttf") format("truetype"), url("/fonts/font-awesome/fa-regular-400.svg#fontawesome") format("svg");
}

/* VEMASALLAUE Template */
@font-face {
  font-family: 'Hellix';
  src: url('/fonts/Hellix/Hellix-Light.woff2') format('woff2');
  font-weight: 300 !important;
  font-style: light;
}

@font-face {
  font-family: 'Hellix';
  src: url('/fonts/Hellix/Hellix-SemiBold.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

/* Tech Contemporary Template */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/Inter/Inter-Regular.woff2?v=3.19") format("woff2")

}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/fonts/Inter/Inter-Medium.woff2?v=3.19") format("woff2"),;
}

//Tech Contemporary
@font-face {
  font-family: 'Manrope';
  font-style: light;
  font-weight: 300;
  src: url("/fonts/Manrope/Manrope-Light.woff2") format("woff2");
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Manrope/Manrope-Regular.woff2") format("woff2");
}

@font-face {
  font-family: 'Manrope';
  font-style: bold;
  font-weight: 600;
  src: url("/fonts/Manrope/Manrope-Bold.woff2") format("woff2");
}

@font-face {
  font-family: 'Acid';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Acid-Grotesk/AcidGrotesk-Regular.woff2") format("woff2")
}